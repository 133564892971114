import * as R from 'ramda'
import React from 'react'
import { graphql } from 'gatsby'
import { Button } from '@material-ui/core'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import { notNilOrEmpty } from '../lib/Helpers'
import Hero from '../components/Hero'
import Layout from '../components/Layout'
import SEO from '../components/SEO'

const selectedNames = []

export default props => {
  const { data } = props
  const { edges: companies } = data.allSanityExhibitors

  const [spinning, setSpinning] = React.useState(false)
  const [selectedName, setSelectedName] = React.useState(null)
  const [displayName, setDisplayName] = React.useState(false)

  function selectName() {
    if (notNilOrEmpty(companies)) {
      const newSelectedName = Math.floor(Math.random() * companies.length)

      setTimeout(() => {
        selectedNames.push({
          index: selectedName,
          name: companies[newSelectedName].node.title
        })
      }, 4000)

      setDisplayName(true)
      setSelectedName(newSelectedName)
      setSpinning(true)
    } else {
      return setDefaultVals
    }
  }

  function setDefaultVals() {
    setSelectedName(null)
    setDisplayName(false)
    setTimeout(selectName, 500)
  }

  const wheelVars = {
    '--nb-item': companies.length,
    '--selected-item': selectedName
  }

  return (
    <Layout>
      <SEO
        title={`Random Exhibitor | Afwerx Fusion Challenge Virtual Showcase`}
      />
      <Hero
        title="Pick a Random Exhibitor"
        className="exhibitor-page"
        img={require('../assets/imgs/exhibitor-hero-default.jpg')}
      />
      <div className="wheel-container">
        <div
          className={`wheel animated fadeIn ${spinning ? 'spinning' : ''}`}
          style={wheelVars}
          onClick={selectName}
        >
          {companies.map(({ node: company }, index) => (
            <div
              className={`wheel-item animated fadeIn ${
                selectedName === index ? 'selected' : ''
              }`}
              key={index}
              style={{ '--item-nb': index }}
            >
              {company.title}
            </div>
          ))}
        </div>
      </div>
      <div className="person-selected">
        <div className="person-selected__name text--center">
          {displayName && selectedName ? (
            <Button variant="outlined" color="primary">
              <AniLink
                cover
                duration={1}
                to={`/exhibitor/${companies[selectedName].node.slug.current}`}
                bg="#000"
                style={{ color: 'black' }}
              >
                Navigate to: {companies[selectedName].node.title}
              </AniLink>
            </Button>
          ) : null}
        </div>
      </div>
    </Layout>
  )
}

export const exhibitorQuery = graphql`
  query {
    allSanityExhibitors {
      edges {
        node {
          title
          company_img {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
              fixed(width: 300) {
                ...GatsbySanityImageFixed
              }
            }
          }
          categories {
            title
          }
          slug {
            current
          }
        }
      }
    }
  }
`
